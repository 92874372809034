import styled from "styled-components";

export const Container = styled.div`
`;

export const Header = styled.div`
    height: 64px;
    border-bottom: 1px solid rgba(91, 97, 110, .2);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const HeaderContainer = styled.div`
    padding: 16px 0;
    width: 1400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Logo = styled.div`
    width: 90%;
`;

export const Main = styled.div`
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 24px;
    @media screen and (max-width: 426px) {
        margin-top: 0;
    }
`;

export const MainContainer = styled.div`
    width: 480px;
    display: flex;
    flex-direction: column;
`

export const Title = styled.h3`
    font-size: 28px;
    font-weight: 500;
    text-align: left;
    line-height: 1.2;
    color: #050f19;
    margin-bottom: 10px;
`

export const Extra = styled.p`
    font-size: 16px;
    color: #5b616e;
    line-height: 23px;
    margin-bottom: 10px;
`

export const Controls = styled.div`
    margin-top: 20px;
`;

export const Label = styled.p`
    font-weight: 700;
    color: #0a0b0d;
    font-size: 12px;
    line-height: 20px;
`;

export const Input = styled.input`
    color: #0a0b0d;
    border: 1px solid rgba(91,97,110,.2);
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    height: 56px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    letter-spacing: 3px;
    margin-right: 5px;
    box-shadow: 0 1px 0 0 rgba(0,0,0,.02) inset;
    transition: 0.3s all;
    width: 100%;
    &:hover {
        border: 1px solid #0052ff;
    }
`;

export const CheckLabel = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
`;

export const CheckBox = styled.input`
    border-radius: 2px;
`;

export const CheckText = styled.p`
    padding-left: 10px;
    font-size: 16px;
    color: #5b616e;
    font-weight: 400;
`;

export const Verify = styled.div`
    margin-top: 32px;
`;

export const VerifyButton = styled.button`
    background-color: #0052ff;
    border-color: #0052ff;
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    padding: 1.1em 2em;
    letter-spacing: .02em;
    width: 100%;
    cursor: pointer;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const ErrorLabel = styled.p`
    color: #cf202f;
`

export const SuccessLabel = styled.p`
    color: #05b169;
`

export const Resend = styled.div`
    margin-top: 16px;
`;

export const ResendButton = styled.button`
    background-color: #fff;
    border: 1px solid rgba(91,97,110,.2);
    border-radius: 8px;
    color: #050f19;
    font-size: 16px;
    font-weight: 550;
    padding: 1.1em 2em;
    letter-spacing: .02em;
    width: 100%;
    cursor: pointer;
`;

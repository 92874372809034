import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { CB, Home, TwoFactor } from "./pages";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CB />} />
          
          {/* Use a route parameter for the dynamic consent_challenge */}
          <Route path="/signin/:challengeId" element={<Home />} />
          
          <Route path="/verify_step_two" element={<TwoFactor />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import React from 'react';

function NoAccess() {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f2f2f2',
        },
        title: {
            color: '#ff0000',
            fontSize: '3em',
        },
        text: {
            color: '#333',
            fontSize: '1.5em',
        },
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Error 403</h1>
            <p style={styles.text}>You can't access this page.</p>
        </div>
    );
}

export default NoAccess;
